import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";

import "./assets/css/bootstrap.min.css";
import "./assets/css/app.min.css";
import "datatables.net-datetime/dist/dataTables.dateTime.css";
import "./assets/css/jquery-ui.min.css";
import "./assets/css/dataTables.bootstrap4.min.css";
import "./assets/css/datatables.min.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-colresize-unofficial/jquery.dataTables.colResize.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";

import "./assets/js/jquery.min.js";
import "jquery/dist/jquery.min.js";
import "./assets/js/jquery-ui.min.js";
import "jquery-ui-dist/jquery-ui.min.js";
import "./assets/js/bootstrap.bundle.min.js";
import "datatables.net-dt/js/dataTables.dataTables.min.js";
import "datatables.net-datetime/js/dataTables.dateTime.js";
import "datatables.net-colresize-unofficial/jquery.dataTables.colResize.js";
import "datatables.net-responsive-dt/js/responsive.dataTables.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons-dt/js/buttons.dataTables.min.js";
import "datatables.net-select-dt/js/select.dataTables.min.js";
import "./assets/js/bootstrap-filestyle.js";
import "./assets/js/number.extension";
import pdfMake from "pdfmake/build/pdfmake.min.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare global {
  interface Window {
    $: any;
  }
}
(global as any).jQuery = require("jquery");
const $ = (global as any).jQuery;
window.$ = $;

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.mount("#app");
