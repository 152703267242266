export function checkIfKeyExists<T, K extends keyof T>(
  obj: T,
  attr: K
): boolean {
  return (
    Object.prototype.hasOwnProperty.call(obj, attr) &&
    typeof obj[attr] !== undefined
  );
}

export function httpGetFromApi(url: string): string | void {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", url, false);
  xmlHttp.withCredentials = true;
  xmlHttp.send(null);
  if (xmlHttp.status == 200) {
    return xmlHttp.responseText;
  }
}

export function deepCopy<T>(obj: T): T {
  if (Object.prototype.toString.call(obj) === "[object Array]")
    return JSON.parse(JSON.stringify(obj));
  else if (Object.prototype.toString.call(obj) === "[object Date]")
    return new Date(+obj) as any;
  else return $.extend(true, {}, obj);
}

export function getFirstElementIdx<T extends any[]>(array: T): number {
  let firstIdx = 0;
  for (const [idx, el] of array.entries()) {
    if (el) {
      firstIdx = idx;
      break;
    }
  }
  console.log(firstIdx);
  return firstIdx;
}

export function getRealLength<T extends any[]>(array: T): number {
  let length = 0;
  array.forEach((el) => {
    if (el) {
      length += 1;
    }
  });
  return length;
}

export function httpGetAsyncFromApi(url: string): any {
  // fetch(url, { credentials: "include" })
  //   .then((resp) => {
  //     if (resp.status == 200) return resp;
  //   })
  //   .catch((err) => {
  //     return err;
  //   });
  return new Promise(function (resolve, reject) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.withCredentials = true;
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState == 4) {
        if (xmlHttp.status == 200) {
          resolve(xmlHttp.response.documentElement.innerHTML);
        } else {
          reject(xmlHttp.status);
        }
      }
    };
    xmlHttp.open("GET", url, true);
    xmlHttp.send(null);
  });
}

export function asyncPost(url: string, data: any) {
  return async () => {
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      const fetchResponse = await fetch(url, settings);
      const resData = await fetchResponse.json();
      return resData;
    } catch (e) {
      return e;
    }
  };
}

export function httpPost(
  url: string,
  data?: any,
  isJson?: boolean,
  ctx?: unknown,
  callback?: (x: XMLHttpRequest, y: unknown) => void
): void {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", url);

  if (isJson) {
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");
  } else {
    xhr.setRequestHeader(
      "Content-Type",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  }

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      console.log(xhr.status);
      console.log(xhr.responseText);
      if (callback) {
        callback(xhr, ctx);
      }
    }
  };
  console.log(data);
  if (isJson) {
    xhr.send(JSON.stringify(data) || "");
  } else {
    xhr.send(data);
  }
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function setupProfitMargin(
  listing: any,
  providerShippingPercentage?: number
) {
  if (
    !listing.was_provider_shipping_cost_updated &&
    providerShippingPercentage
  ) {
    listing.provider_shipping_percentage = providerShippingPercentage ?? 0;
  }
  if (listing.provider_price && listing.ml_comission) {
    listing.provider_shipping_cost =
      listing.provider_price *
      ((listing.provider_shipping_percentage ?? 0) / 100).round(2);
    listing["ml_costs"] =
      listing.ml_comission + (listing.free_shipping_cost ?? 0);
    listing["provider_costs"] = (
      listing.provider_price + listing.provider_shipping_cost
    ).round(2);
    listing["all_costs"] = (listing.ml_costs + listing.provider_costs).round(3);
    listing["profit_margin"] =
      (listing.price - listing.ml_costs) / listing.provider_costs;
  }
}

export function getExcelSheetName(this: any) {
  const date = new Date();
  const padToTwoDigits = (num: number) => num.toString().padStart(2, '0');
  const day = padToTwoDigits(date.getDate());
  const month = padToTwoDigits(date.getMonth() + 1); // Meses em JavaScript vão de 0 a 11
  const year = date.getFullYear();
  const hours = padToTwoDigits(date.getHours());
  const minutes = padToTwoDigits(date.getMinutes());
  const seconds = padToTwoDigits(date.getSeconds());

  // Substituindo : por - e / por -
  const newDate = `${day}-${month}-${year}`;
  const result = `${newDate}`;
  
  return result;
}




