export default class ExportListing {
    id: number;
    name: string;
    description: string;

    atacado: number;
    venda: number;
    cod: string;
    image: string;
    sku: string;
    observation: string;
    
    provider_price: number;
    group_id: number;
    active: boolean;

    constructor(listing: any) {
        this.id = listing.id;
        this.name = listing.title;
        this.description = listing.export_description;

        this.atacado = listing.atacado;
        this.venda = listing.venda;
        this.cod = listing.cod;
        this.sku = listing.provider_sku;
        this.image = listing.thumbnail;
        this.observation = listing.observation;
        this.sku = listing.provider_sku;
        this.provider_price = listing.provider_price;

        this.group_id = listing.group_id;
        this.active = true;
    }
}