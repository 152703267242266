export default class ProviderListingOrder {
  sku: string;
  seller: string;
  description: string;
  provider: string;
  price: number;
  dolar_price: number | null;
  qtd: number;
  listing_id: number;
  start_stock?: number;
  end_stock?: number;

  constructor(
    sku: string,
    seller: string,
    description: string,
    provider: string,
    price: number,
    dolar_price: number | null,
    qtd: number,
    listing_id: number
  ) {
    this.sku = sku;
    this.seller = seller;
    this.description = description;
    this.provider = provider;
    this.qtd = qtd;
    this.price = price;
    this.dolar_price = dolar_price;
    this.listing_id = listing_id;
  }
}
