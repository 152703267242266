/* eslint-disable */

interface Number {
  round(places: number): number;
  thousandsFormat(): string;
}

Number.prototype.round = function roundNumber(decimals) {
  if (!("" + this).includes("e")) {
    // @ts-ignore:
    return +(Math.round(this + "e+" + decimals) + "e-" + decimals);
  } else {
    var arr = ("" + this).split("e");
    var sig = "";
    if (+arr[1] + decimals > 0) {
      sig = "+";
    }
    return +(
      // @ts-ignore:
      (Math.round(+arr[0] + "e" + sig + (+arr[1] + decimals)) + "e-" + decimals)
    );
  }
};

Number.prototype.thousandsFormat = function formatter() {
  return this.toLocaleString("pt-br", { maximumFractionDigits: 2 });
};
