import ProviderListingOrder from "./ProviderListingOrder";
export default class ProviderOrder {
  id: number;
  order_name: string;
  is_tmp: boolean;
  is_finished: boolean;
  order_items: ProviderListingOrder[];
  date_created: string;
  last_updated: string;
  dolar_value: number;
  shipping_cost_value: number;

  constructor(
    bud_get_order_name: string,
    order_items: ProviderListingOrder[],
    dolar_value: number,
    shipping_cost_value: number,
    is_finished = false,
    is_tmp = true
  ) {
    if (!is_finished) {
      this.is_tmp = is_tmp;
    } else {
      this.is_tmp = false;
    }
    this.order_name = bud_get_order_name;
    this.dolar_value = dolar_value;
    this.shipping_cost_value = shipping_cost_value;
    this.is_finished = is_finished;
    this.order_items = order_items;
    const datetime = new Date().toLocaleString();
    this.date_created = datetime;
    this.last_updated = datetime;
    this.id = -1;
  }
}
