<script>
import { defineComponent } from "vue";
import { store } from "../store/index";
import { mapState } from "pinia";
export default defineComponent({
  computed: {
    ...mapState(store, ["dolarAdvice", "providerShippingPercentage"]),
  },
});
</script>
<template>
  <div style="width: 100%; height: 100px; display: block"></div>
</template>
