import ExportItem from "@/models/ExportItem";
import ProviderOrder from "@/models/ProviderOrder";
import Seller from "@/models/Seller";
import { defineStore } from "pinia";
export const store = defineStore({
  id: "userStore",
  state: () => {
    return {
      listings: [] as any[],
      exportListings: [] as ExportItem[],
      exportInfo: {} as any,
      listingGroups: [] as any[],
      idToListingGroup: {} as Record<string, any>,
      orders: [] as any[],
      idToOrder: {} as Record<string, any>,
      claims: [] as any[],
      idToClaim: {} as Record<string, any>,
      shipments: [] as any[],
      idToShipping: {} as Record<string, any>,
      providerListings: [] as any[],
      idToListing: {} as Record<string, any>,
      providerOrders: [] as ProviderOrder[],
      providerOrderTmpIdx: -1,
      dolar: -1,
      dolarAdvice: -1,
      providerShippingPercentage: -1,
      stockErrorData: {} as Record<string, Record<string, any>>,
      budGetOrderName: '' as string,
      sellers: [] as Seller[],
      budgetActive: false,
      tmpDolar: null as number | null,
      tmpProviderShippingCost: null as number | null,
      listingsExported: 1,
      catalogMonitorListings: {} as Record<string, any>[],
      exportType: 'venda'
    };
  },
  getters: {
    cProviderOrder(): ProviderOrder {
      return this.providerOrders[
        this.providerOrderTmpIdx === -1
          ? this.providerOrders.length - 1
          : this.providerOrderTmpIdx
      ];
    },
    tmpProviderListingOrders(): any[] {
      return this.providerOrders[
        this.providerOrderTmpIdx === -1
          ? this.providerOrders.length - 1
          : this.providerOrderTmpIdx
      ].order_items;
    },
  },
  actions: {
    clearTmpListings() {
      if (this.providerOrders[this.providerOrderTmpIdx].is_tmp) {
        this.providerOrders[this.providerOrderTmpIdx].order_items = [];
        console.log(this.providerOrders[this.providerOrderTmpIdx].order_items);
      }
    },
  },
});
