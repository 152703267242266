import { createRouter, createWebHistory } from "vue-router";
import ListagemAnuncios from "../views/ListagemAnuncios.vue";
import ListagemCompras from "../views/ListagemCompras.vue";
import ListagemCatalogo from "../views/ListagemCatalogo.vue";
import ListagemReclamacoes from "../views/ListagemReclamacoes.vue";
import ExportacaoAtacado from "../views/ExportacaoAtacado.vue";
import BaseLayout from "../layouts/BaseLayout.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/listagem" },
    { path: "/exportacao", component: ExportacaoAtacado },
    {
      path: "/listagem",
      redirect: "/listagem/anuncios",
      component: BaseLayout,
      children: [
        {
          path: "anuncios",
          name: "home",
          component: ListagemAnuncios,
        },
        {
          path: "compras",
          name: "orders",
          component: ListagemCompras,
        },
        {
          path: "catalogo",
          name: "catalogo",
          component: ListagemCatalogo,
        },
        {
          path: "trocas",
          name: "trocas",
          component: ListagemReclamacoes,
        },
      ],
    },
  ],
});

export default router;
